<template>
  <b-modal ref="modal" centered :title="modalTitle" hide-footer @hide="resetForm">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(handleAddEditTax)">
        <b-form-group label="الاسم" label-for="name-input">
          <ValidationProvider name="الاسم" rules="required" v-slot="{ errors }">
            <b-form-input id="name-input" v-model="tax.name" :state="errors.length === 0 ? null : false"></b-form-input>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
        <b-form-group label="القيمة" label-for="value-input">
          <ValidationProvider name="القيمة" rules="required|numeric|min_value:1" v-slot="{ errors }">
            <b-input-group>
              <b-form-input
                id="value-input"
                v-model="tax.value"
                step="1"
                min="0"
                max="100"
                type="number"
                :state="errors.length === 0 ? null : false"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text>%</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
        <b-form-group label="الوصف" label-for="description-input">
          <b-form-textarea id="description-input" v-model="tax.description"></b-form-textarea>
        </b-form-group>
        <footer class="text-right modal-footer">
          <b-button type="submit" variant="primary">{{ isEditMode ? 'تعديل' : 'اضافة' }}</b-button>
          <b-button type="button" variant="secondary" @click="hideModal">الغاء</b-button>
        </footer>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import { mapActions } from 'vuex'
import { BFormGroup, BFormInput, BFormTextarea, BButton, BModal, BFormInvalidFeedback, BInputGroupAppend, BInputGroupText, BInputGroup } from 'bootstrap-vue'
import { required, numeric, min_value } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BModal,
    BFormInvalidFeedback,
    BInputGroupAppend,
    BInputGroupText,
    BInputGroup
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false
    },
    initialTax: {
      type: Object,
      default: () => ({
        id: 0,
        name: '',
        value: 0,
        description: ''
      })
    },
    modalTitle: {
      type: String,
      default: 'اضافة ضريبة'
    }
  },
  data() {
    return {
      locale: 'ar',
      tax: { ...this.initialTax }
    }
  },
  mounted() {
    // Switch to Arabic in validation
    localize(this.locale)
  },
  watch: {
    initialTax(newTax) {
      this.tax = { ...newTax }
    }
  },
  methods: {
    ...mapActions('taxes', ['createTax', 'updateTax']),
    showModal() {
      this.$refs.modal.show()
    },
    hideModal() {
      this.$refs.modal.hide()
    },
    handleAddEditTax() {
      this.$refs.observer.validate().then((success) => {
        if (success) {
          //convert the percentage to a decimal
          this.tax.value =  this.tax.value / 100
          const action = this.isEditMode ? 'updateTax' : 'createTax'
          this[action](this.tax)
            .then(() => {
              this.$emit('tax-saved', this.tax)
              this.$refs.modal.hide()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'تم التنفيذ بنجاح',
                  icon: 'CheckIcon',
                  variant: 'success'
                }
              })
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'خطأ في السيرفر',
                  icon: 'TrashIcon',
                  variant: 'danger'
                }
              })
            })
        }
      })
    },
    resetForm() {
      this.tax = { ...this.initialTax }
    }
  }
}
</script>

<style scoped>
::v-deep .modal-title {
  font-size: 1.6rem;
  font-weight: 400;
}
/* Add your custom styles here */
</style>
