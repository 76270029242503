<template>
  <b-card-code title="">
    <b-button style="margin-top: -60px; float: right" variant="primary" @click="showAddModal">إضافة ضريبة</b-button>
    <vue-good-table
      :columns="columns"
      :rows="this.taxes"
      :rtl="direction"
      :lineNumbers="true"
      :search-options="{
        enabled: true
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageSize
      }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'" class="text-nowrap">
          <div class="d-flex justify-content-start">
            <button class="btn btn-primary mr-1" @click="showEditModal(props.row)">
              <feather-icon icon="EditIcon" size="16" />
            </button>
            <DeleteModel :displayName="props.row.name" @confirm-delete="handleDelete(props.row.id)" />
          </div>
        </span>
        <span v-else-if="props.column.field === 'value'">
          {{ Math.round(props.row.value * 100) + '%' }}
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> عرض الصفحة الأولى </span>

            <b-form-select
              v-model="pageSize"
              :options="['3', '7', '10', '15', '20']"
              class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })"
            />
            <span class="text-nowrap"> من {{ props.total }} العناصر </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageSize"
              first-number
              last-number
              align="right"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <AddTax ref="addEditTaxModal" :isEditMode="isEditMode" :initialTax="selectedTax" :modalTitle="modalTitle" @tax-saved="handleTaxSaved" />
  </b-card-code>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DeleteModel from '@core/components/DeleteModel.vue'
import AddTax from './AddTax.vue'
import store from '@/store'
import {
  BButton,
  BAvatar,
  BBadge,
  BModal,
  VBModal,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormDatepicker
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BAvatar,
    BBadge,
    BModal,
    BCardCode,
    DeleteModel,
    VBModal,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    VueGoodTable,
    AddTax
  },
  data() {
    return {
      isEditMode: false,
      selectedTax: {
        id: 0,
        name: '',
        value: 0,
        description: ''
      },
      modalTitle: 'اضافة ضريبة',

      pageSize: 7,

      dir: false,
      columns: [
        {
          label: 'الاسم',
          field: 'name',
          sortable: true
        },

        {
          label: 'الوصف',
          field: 'description',
          sortable: false
        },
        {
          label: 'القيمة',
          field: 'value',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'التفاصيل',
          field: 'action',
          sortable: false
        }
      ]
    }
  },
  async mounted() {
    await this.getTaxesFromGrid()
  },

  methods: {
    ...mapActions('taxes', ['fetchTaxes', 'deleteTax']),

    async getTaxesFromGrid() {
      await this.fetchTaxes({ pageSize: this.pageSize, pageNumber: this.pageNumber })
    },

    showAddModal() {
      this.isEditMode = false
      this.selectedTax = {
        id: 0,
        name: '',
        value: 0,
        description: ''
      }
      this.modalTitle = 'اضافة ضريبة'
      this.$refs.addEditTaxModal.showModal()
    },
    showEditModal(tax) {
      this.isEditMode = true
      this.selectedTax = { ...tax }
      this.selectedTax.value = Math.round(this.selectedTax.value * 100)
      this.modalTitle = 'تعديل ضريبة'
      this.$refs.addEditTaxModal.showModal()
    },

    handleTaxSaved(tax) {
      // Handle tax saved event (e.g., refresh tax list)
      // console.log('Tax saved:', tax)
    },
    async handleDelete(id) {
      await this.deleteTax(id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم الحذف بنجاح',
              icon: 'TrashIcon',
              variant: 'success'
            }
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'حدث خطأ أثناء الحذف',
              icon: 'XCircleIcon',
              variant: 'danger'
            }
          })
        })
    }
  },
  computed: {
    ...mapGetters('taxes', {
      taxes: 'getTaxes',
      totalCount: 'totalCountData',
      pagesCount: 'pagesCount'
    }),

    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    }
  }
}
</script>

<style>
/* Add your custom styles here */
</style>
